<template>
<div id="service">
  <div class="service-wrap">
    <div class="service-info">
      <div class="serviceLeft">
        <el-carousel height="380px">
          <el-carousel-item v-for="(item,index) in bannerList" :key="index">
            <img :src="item" />
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="serviceRight">
        <h3>{{serviceInfo.serviceTradeName}}</h3>
        <div class="address" @click="openAddress">
          <span>服务地址</span>
          <label>{{serviceModel.address}}{{serviceModel.houseNumber}}</label>
          <i class="el-icon-arrow-right"></i>
        </div>
        <div class="address" @click="openTime">
          <span>上门时间</span>
          <label>{{serviceModel.arrivalTime }} {{serviceModel.toDoorTimeName }}</label>
          <i class="el-icon-arrow-right"></i>
        </div>
        <div class="goodsPrice">
          <el-collapse v-model="activeCollapse">
            <el-collapse-item name="1">
              <template slot="title">
                选择服务
                <div class="serviceGoods">
                  <span>{{serviceGoods.goodsName}}</span><label>￥{{serviceGoods.goodsPrice}}</label>
                  <i class="el-icon-close"></i>{{serviceGoods.goodsNum}}{{serviceGoods.unitType}}
                </div>
              </template>
              <div class="price-item" :class="{active:sub==index}" @click="toggle(index)" v-for="(item,index) in serviceInfo.temporaryServiceGoodsPriceList" :key="index">
                <strong>{{item.goodsName}}</strong>
                <span>{{item.goodsDescribe}}</span>
                <p>
                  <label>￥{{item.goodsPrice}}</label>
                  <i class="el-icon-close"></i>{{item.goodsNum}}{{serviceGoods.unitType}}
                </p>
              </div>
            </el-collapse-item>
          </el-collapse>
        </div>
        <div class="address" @click="remarkShow=true">
          <span>备注</span>
          <label>{{serviceModel.remark }}</label>
          <i class="el-icon-arrow-right"></i>
        </div>
        <div class="address payChannel">
          <span>支付方式</span>
          <el-radio-group v-model="serviceModel.payChannel">
              <el-radio label="1"><img src="@/assets/imgs/wxPay.png">微信</el-radio>
              <el-radio label="2"><img src="@/assets/imgs/zhifubao.png">支付宝</el-radio>
              <el-radio label="3"><img src="@/assets/imgs/jianhang.png">建行支付</el-radio>
            </el-radio-group>
        </div>
        <div class="btnBox">
          <label><i>￥</i>{{serviceGoods.realityPrice}}</label>
          <el-button class="sureBtn" :disabled="disabled" @click="reservation" type="primary" v-if="serviceInfo.type==1">立即下单</el-button>
          <el-button class="sureBtn" :disabled="disabled"  @click="reservation" type="primary" v-if="serviceInfo.type==2">立即预约</el-button>
        </div>
      </div>
    </div>
    <div class="serveHeader">
      <span>服务内容</span>
    </div>
    <div class="serviceDescription" v-html="serviceInfo.serviceDescription"></div>
  </div>
  <!-- 上门时间 -->
  <div class="timeBox">
    <el-dialog title="请选择上门时间" :visible.sync="timeShow" width="700px">
      <div class="timeHeader">
        <div class="time-item" :class="{activeTime:subTime==index}" @click="toggleTime(index)" v-for="(item,index) in dayList" :key="index">
          <span>星期{{item.week}}</span>
          <label>{{item.month}}月{{item.day}}日</label>
        </div>
      </div>
      <div class="appointment">
        <div class="appointment-item" :class="[{activeAppoint:subAppoint===index},{activeDis:subTime==0&&item.becomeDue==1}]" @click="toggleAppoint(index,item)" v-for="(item,index) in appointmentTime" :key="index">
          <span>{{item.dictLabel}}</span>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="sureBtn" type="primary" @click="sureTime">确 认</el-button>
      </span>
    </el-dialog>
  </div>
  <!-- 添加地址 -->
  <el-dialog title="添加服务地址" :visible.sync="addressShow" width="700px">
    <div class="addressBox">
      <el-form :inline="true" :model="serviceModel" label-width="150px">
        <el-form-item label="服务地址" prop="address">
          <el-input @focus="openMap" v-model="serviceModel.address" placeholder="请选择服务地址"></el-input>
        </el-form-item>
        <el-form-item label="楼层/单元室/门牌号" prop="houseNumber">
          <el-input v-model="serviceModel.houseNumber" clearable placeholder="请输入楼层/单元室/门牌号"></el-input>
        </el-form-item>
        <el-form-item label="联系人" prop="liaisonNan">
          <el-input v-model="serviceModel.liaisonNan" placeholder="请输入联系人"></el-input>
        </el-form-item>
        <el-form-item label="性别" prop="sex">
          <el-radio-group v-model="serviceModel.sex">
            <el-radio label="0">先生</el-radio>
            <el-radio label="1">女士</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="手机号" prop="liaisonNanPhone">
          <el-input v-model="serviceModel.liaisonNanPhone" placeholder="请输入手机号"></el-input>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="addressShow = false">取 消</el-button>
      <el-button type="primary" @click="saveAddress">确 定</el-button>
    </span>
  </el-dialog>
  <!-- 地图 -->
  <div class="mapBox">
    <el-dialog title="选择服务地址" :visible.sync="dialogMap" width="700px">
      <div class="serviceMap">
        <el-amap-search-box class="search-box" :search-option="searchOption" :on-search-result="onSearchResult">
        </el-amap-search-box>

        <el-amap ref="map" vid="amapDemo" :events="events" zoom="12" :center="center" class="amap-demo">
          <el-amap-marker key="100" :position="center"></el-amap-marker>
        </el-amap>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogMap = false">取 消</el-button>
        <el-button type="primary" @click="setMap">确 定</el-button>
      </span>
    </el-dialog>
  </div>
  <div class="remarkBox">
    <el-dialog title="订单备注" :visible.sync="remarkShow" width="700px">
      <el-input type="textarea" v-model="serviceModel.remark"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button class="sureBtn" type="primary" @click="remarkShow=false">确 认</el-button>
      </span>
    </el-dialog>
  </div>
<!-- 支付 -->
  <div class="payDialog">
    <el-dialog
        title="订单支付"
        :visible.sync="payShow"
        width="600px"
    >
      <div class="row-select">
        <el-radio-group v-model="payChannel" @change="changeAmount">
          <el-radio label="1"><div class="row-title"><img src="@/assets/imgs/wxPay.png"><p>微信</p></div></el-radio>
          <el-radio label="2"><div class="row-title"><img src="@/assets/imgs/zhifubao.png"><p>支付宝</p></div></el-radio>
          <el-radio label="3"><div class="row-title"><img src="@/assets/imgs/jianhang.png"><p>建行支付</p></div></el-radio>
        </el-radio-group >
      </div>
      <div class="qrcodeBox">
<!--        <div class="qrcode" ref="qrCodeUrl"></div>-->
        <div class="qrcode" id="qrcode" ref="qrcode"></div>
      </div>
      <div class="payMount">
        <p>支付金额：{{realPrice}}</p>
      </div>
      <span slot="footer" class="dialog-footer">
          <el-button @click="payShow = false">取 消</el-button>
          <el-button type="primary" @click="determine">确 定</el-button>
        </span>
    </el-dialog>
  </div>
</div>
</template>

<script>
import { getWeekDate } from '@/utils/index.js'
import {
  addUserPlacesAnOrder,
  confirmPayment,
  getServiceDetails,
  getPlaceOrderAddress,
  setPlaceOrderAddress,
  getDictData,
  getPayStatus
} from "@/api/index.js";
import QRCode from "qrcodejs2";
export default {
  name: "service",
  data() {
    let _this = this;
    return {
      sub: '0',
      subTime: '0',
      subAppoint: '',
      serviceInfo: {},
      serviceGoods: {},
      bannerList: [],
      serviceModel: {
        address: '',
        houseNumber: "",
        latitude: "",
        longitude: "",
        liaisonNanPhone: '',
        sex: '',
        liaisonNan: '',
        arrivalTime: '',
        toDoorTime: '',
        toDoorTimeName: '',
        payChannel: '1',
        remark: ''
      },
      addressShow: false,
      realPrice: 0,
      dialogMap: false,
      remarkShow: false,
      center: [93.51452, 42.82699],
      events: {
        init() {
          // _this.getRecruiterEnterpriseDetails()
        },
        click(e) {
          _this.center = [e.lnglat.lng, e.lnglat.lat];
          _this.getFormattedAddress();

        }
      },
      searchOption: {
        // 限制搜索城市的范围
        citylimit: false,
      },
      timeShow: false,
      payShow:false,
      payChannel:'1',
      dayList: [],
      appointmentTime: [],
      activeCollapse: [],
      serviceUserOrderId: '',
      orderStatus: '',
      disabled:false,
      userInfo:{}
    }
  },
  mounted() {
    this.getServiceDetails()
    this.getPlaceOrderAddress()
    this.dayList = getWeekDate({
      baselineDate: new Date(),
      range: 7
    });
    this.getDictData('appointment_time')
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
    this.timer = setInterval(() => {
      if(this.payShow){
        setTimeout(this.getPayStatuss, 0);
      }
    }, 3000);
  },
  //页面切除后解除定时
  beforeDestroy() {
    clearInterval(this.timer);
    this.timer = null;
  },
  methods: {
    getPayStatuss(){
      getPayStatus({rechargeAmount: this.rechargeAmount, payChannel: this.payChannel, channel: "2"}).then(res=>{
        if(res.data){
          this.$router.push({
            path: '/paySucess',
            query:{
              type:2
            }
          })
        }
      })
    },
    reservation() {
       if(this.userInfo.type==3){
          this.$router.push({
            path: '/login'
          })
            this.$message({
                message:'请登陆',
                type: 'warning'
            })
            return
        }
      if (!this.serviceModel.arrivalTime) {
        this.$message({
          message: '请选择上门时间',
          type: 'warning'
        })
        return
      }
      if (!this.serviceModel.address) {
        this.$message({
          message: '请选择服务地址',
          type: 'warning'
        })
        return
      }
        if(this.serviceInfo.type === '2'){
          this.disabled = true
          addUserPlacesAnOrder({
            "arrivalTime": this.serviceModel.arrivalTime,
            "payChannel": this.serviceModel.payChannel,
            "placeOrderPhone": this.serviceModel.liaisonNanPhone,
            "remark": this.serviceModel.remark,
            "serviceGoodsId": this.serviceInfo.id,
            "serviceGoodsPriceId": this.serviceGoods.id,
            "toDoorTime": this.serviceModel.toDoorTime,
            "type": this.serviceInfo.type
          }).then(res => {
            if (res.code == 200) {
              this.serviceUserOrderId = res.data.serviceUserOrderId
              this.orderStatus = res.data.orderStatus
              if (this.orderStatus == '0') {
                this.$message({
                  message: '预约成功！',
                  type: 'success'
                })
                this.$router.push({
                  path: '/success',
                  query: {
                    type: this.serviceInfo.type
                  }
                })
              }
            } else {
              this.$message({
                message: res.msg,
                type: 'warning'
              })
            }
          })
        }else if(this.serviceInfo.type === '1'){
          addUserPlacesAnOrder({
            "arrivalTime": this.serviceModel.arrivalTime,
            "payChannel": this.serviceModel.payChannel,
            "placeOrderPhone": this.serviceModel.liaisonNanPhone,
            "remark": this.serviceModel.remark,
            "serviceGoodsId": this.serviceInfo.id,
            "serviceGoodsPriceId": this.serviceGoods.id,
            "toDoorTime": this.serviceModel.toDoorTime,
            "type": this.serviceInfo.type
          }).then(res => {
            if (res.code == 200) {
              this.serviceUserOrderId = res.data.serviceUserOrderId
              this.orderStatus = res.data.orderStatus
              if (this.orderStatus === '3') {
                this.$message({
                  message: '下单成功！',
                  type: 'success'
                })
                this.confirmPayments();
              }
            } else {
              this.$message({
                message: res.msg,
                type: 'warning'
              })
            }
          })
        }

    },
    /**
     * 调用支付
     */
    confirmPayments(){
      this.payShow = true
      //this.$refs.qrCodeUrl.innerHTML = ''
      if(document.getElementById('qrcode') != null){
        document.getElementById('qrcode').innerHTML = "";
      }
      confirmPayment({
        channel: 2,
        orderStatus: this.orderStatus,
        payChannel: this.serviceModel.payChannel,
        serviceUserOrderId: this.serviceUserOrderId
      }).then(res => {
        this.realPrice = res.data.realPrice;
        /*new QRCode(this.$refs.qrCodeUrl, {
          text: res.data.code_url, //可以是链接，也可以是文本
          width: 132, //二维码的宽度
          height: 132, //二维码的高度
          colorLight: '#ffffff', //二维码背景色
          correctLevel: QRCode.CorrectLevel.H //纠错等级
        })*/
        new QRCode("qrcode", {
          width: 132,
          height: 132,
          colorLight: '#ffffff', //二维码背景色
          correctLevel: QRCode.CorrectLevel.H, //纠错等级
          text: res.data.code_url // 生成二维码的链接
        });
      })
    },
    //切换
    changeAmount(){
      this.serviceModel.payChannel = this.payChannel;
      this.confirmPayments();
    },
    /**
     * 确定
     */
    determine(){
      this.payShow = false;
      //刷新
      this.getServiceDetails();
    },
    sureTime() {
      this.timeShow = false
      var time = this.dayList[this.subTime]
        console.log(this.subAppoint)
      if(this.subAppoint!==''){
          var appoint = this.appointmentTime[this.subAppoint]
          this.serviceModel.arrivalTime = time.year + '-' + time.month + '-' + time.day
          this.serviceModel.toDoorTime = appoint.dictValue
          this.serviceModel.toDoorTimeName = appoint.dictLabel
      }
   
    },
    getDictData(dictType) {
      getDictData({
        dictType: dictType
      }).then(res => {
        if (dictType == 'appointment_time') {
          this.appointmentTime = res.rows
        }
      })
    },
    openTime() {
      this.timeShow = true
    },
    toggle(index) {
      this.sub = index
      this.serviceGoods = this.serviceInfo.temporaryServiceGoodsPriceList[this.sub]
    },
    toggleTime(index) {
      this.subTime = index
      this.subAppoint = ''
    },
    toggleAppoint(index,item) {
      if(item.becomeDue==0||this.subTime!=0){
         this.subAppoint = index
      }
    },
    setMap() {
      this.dialogMap = false
      this.serviceModel.longitude = this.center[0]
      this.serviceModel.latitude = this.center[1]
    },
    getFormattedAddress() {
      window.AMap.plugin('AMap.Geocoder', () => {
        let GeocoderOptions = {
          city: '全国'
        };
        let geocoder = new window.AMap.Geocoder(GeocoderOptions);
        geocoder.getAddress(this.center, (status, result) => {
          console.log('通过经纬度拿到的地址', result);
          if (status === 'complete' && result.info === 'OK') {
            this.serviceModel.address = result.regeocode.formattedAddress;
          }
        });
      });
    },
    onSearchResult(pois) {
      console.log(pois)
      if (pois && pois.length > 0) {
        //如果长度为1则无需转化
        let poi = pois[0];
        let lng = poi["lng"];
        let lat = poi["lat"];
        this.center = [lng, lat];
        this.getFormattedAddress()
      }
    },
    openMap() {
      this.dialogMap = true
    },
    openAddress() {
       if(this.userInfo.type==3){
          this.$router.push({
            path: '/login'
          })
            this.$message({
                message:'请登陆',
                type: 'warning'
            })
        }else{
           this.addressShow = true
        }
    },
    saveAddress() {
      setPlaceOrderAddress({
        houseNumber: this.serviceModel.houseNumber,
        latitude: this.serviceModel.latitude,
        longitude: this.serviceModel.longitude,
        address: this.serviceModel.address,
        liaisonNan: this.serviceModel.liaisonNan,
        sex: this.serviceModel.sex,
        liaisonNanPhone: this.serviceModel.liaisonNanPhone
      }).then(res => {
        if (res.code == 200) {
          this.$message({
            message: '保存成功',
            type: 'success'
          })
          this.addressShow = false
          this.getPlaceOrderAddress()
        } else {
          this.$message({
            message: res.msg,
            type: 'warning'
          })
        }
      })
    },
    getServiceDetails() {
      getServiceDetails({
        serviceTrade: this.$route.query.serviceTrade
      }).then(res => {
        this.serviceInfo = res.data
        this.serviceGoods = res.data.temporaryServiceGoodsPriceList[this.sub]||{}
        if( res.data.rotationChart){
          this.bannerList = res.data.rotationChart.split(',')
        }
      })
    },
    getPlaceOrderAddress() {
      getPlaceOrderAddress().then(res => {
        var info = res.data
        this.serviceModel.address = info.address
        this.serviceModel.houseNumber = info.houseNumber
        this.serviceModel.latitude = info.latitude
        this.serviceModel.longitude = info.longitude
        this.serviceModel.sex = info.sex
        this.serviceModel.liaisonNan = info.liaisonNan
        this.center[0] = info.longitude || 93.51452
        this.center[1] = info.latitude || 42.82699
        this.serviceModel.liaisonNanPhone = info.liaisonNanPhone
      })
    }
  }
};
</script>

<style lang="scss" scoped>
#service {
  width: 100%;
  min-height: 200px;
  background: linear-gradient(0deg, #F5F5F5 1%, #D6E9FF 100%);
  overflow: hidden;
  padding-top: 24px;
}

.service-wrap {
  width: 1200px;
  min-height: 200px;
  margin: 0 auto;
}
.row-select{
  clear: both;
}
.service-info {
  background: #fff;
  min-height: 200px;
  overflow: hidden;
  margin-bottom: 20px;

  .serviceLeft {
    float: left;
    width: 500px;

    img {
      width: 100%;
      height: 380px;
      cursor: pointer;
      border-radius: 10px;
    }
  }

  .serviceRight {
    float: left;
    width: 660px;
    padding: 15px 20px;

    h3 {
      color: #666;
      font-size: 17px;
    }
  }

  .address {
    margin-top: 15px;
    border-bottom: 1px solid #eee;
    padding-bottom: 15px;
    width: 600px;
    font-size: 14px;
    color: #666;

    span {
      display: inline-block;
      width: 57px;
      margin-right: 15px;
    }

    label {
      font-size: 13px;
      color: #222;
      display: inline-block;
      width: 500px;
      cursor: pointer;
    }

  }
}
.payDialog{

  .row-title{
    margin-top:24px;
    margin-bottom: 24px;
    display:inline-block;
    margin-right: 20px;
    img{
      float: left;
      width: 18px;
      height: 18px;
      margin-right:5px;
    }
    p{
     position:relative;
     top:2px
    }
    span{
      display: block;
      float: left;
      font-size: 14px;
      font-weight: 500;
      color: #414a60;
      margin-left: 8px;
    }
  }
  .qrcode{
    margin-left:212px;
    img{
      width: 160px;
      margin: 30px auto;
    }
  }
  .payMount{
    text-align: center;
     margin-top: 15px;
         font-size: 20px;
    font-weight: 400;
    color: #fc6c38;
  }
}
.serviceMap {
  overflow: hidden;

  .amap-demo {
    height: 280px;
  }

  .el-vue-search-box-container {
    width: 644px;
    margin-left: 0;
    margin-bottom: 10px;
    border: 1px solid #eee;
    padding-right: 10px;
  }
}

.goodsPrice {
  .active {
    border: 1px solid #5dd5c8;
    background-color: #f2fcfb !important;
  }

  h4 {
    font-size: 14px;
    color: #666;
    font-weight: 400;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .price-item {
    width: 480px;
    height: 45px;
    line-height: 45px;
    background-color: #f5f5f5;
    margin-top: 8px;
    border-radius: 5px;
    padding: 0 15px;
    cursor: pointer;

    p {
      float: right;
      display: block;
      font-size: 14px;
      letter-spacing: 1px;
    }

    strong {
      font-weight: 400;
      font-size: 14px;
    }

    span {
      color: #666;
      display: inline-block;
      font-size: 12px;
      margin-left: 20px;
    }

    label {
      font-weight: bold;
      color: rgb(255, 118, 6);
      font-size: 14px;
    }
  }

  .serviceGoods {
    margin-left: 18px;

    label {
      font-weight: 600;
      display: inline-block;
      margin-left: 10px;
      color: #ff7606;
    }
  }
}

.timeBox {
  .timeHeader {
    height: 55px;
    background: #00C9A5;
    padding: 10px;
  }

  .time-item {
    float: left;
    width: 66px;
    text-align: center;
    color: #fff;
    padding: 15px;
    border-radius: 10px 10px 0 0;
    cursor: pointer;

    span {
      display: block;
      margin-bottom: 5px;
      font-size: 15px;
    }
  }

  .activeTime {
    background: #fff;
    color: #00C9A5;
  }

  .appointment {
    height: 60px;
    margin:30px auto ;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

  }

  .appointment-item {
    float: left;
    height: 45px;
    line-height: 45px;
    background-color: #f5f5f5;
    margin-top: 3px;
    border-radius: 5px;
    width: 100px;
    padding: 0 10px;
    margin-left: 15px;
    font-size: 14px;
    text-align: center;
    color: #666;
    cursor: pointer;
  }

  .activeAppoint {
    border: 1px solid #5dd5c8;
    background-color: #f2fcfb;
  }
  .activeDis{
      background-color: #ccc;
      border: none;
  }
}

.sureBtn {
  width: 200px;
  height: 42px;
  border-radius: 4px;
  line-height: 20px;
  background: #00C9A5;
  clear: both;
  border: none;
  text-align: center;
  font-size: 15px;
  color: #fff;
  margin: 10px 0;
}

.btnBox {
  margin-top: 15px;

  label {
    color: rgb(255, 118, 6);
    font-size: 30px;
    display: inline-block;
    margin-right: 30px;
    position: relative;
    top: 3px;
  }

  i {
    font-size: 20px;
    font-style: normal;
  }
}

.serveHeader {
  margin-top: 20px;
  background-color: #f7f7f7;
  border: 1px solid #eee;
  border-bottom: 1px solid #e4393c;
  height: 40px;
  line-height: 40px;
  margin-bottom: 20px;

  span {
    display: inline-block;
    font-size: 14px;
    background-color: #e4393c;
    color: #fff;
    cursor: default;
    padding: 0 25px;
  }
}
</style><style lang="scss">
.addressBox {
  .el-form-item {
    margin-bottom: 20px;
  }

  .el-form-item__label {
    font-size: 16px;
    color: #000000;
  }

  .el-input__inner {
    width: 490px;
    height: 40px;
    background: #F5F5F5;
    border-radius: 5px;
    border: none;
    font-size: 16px;
    color: #999999;
  }

}

.mapBox {
  .el-dialog {
    height: 485px;
  }

  .el-dialog__body {
    padding: 10px 20px;
  }
}

.timeBox {
  .el-dialog__body {
    padding: 0;
  }

  .el-dialog__footer {
    text-align: center;
  }

  .el-dialog__header {
    background: #00C9A5;

    .el-dialog__title {
      color: #fff;
    }

    .el-dialog__headerbtn .el-dialog__close {
      color: #fff;
    }
  }
}

.serviceDescription {
  img {
    width: 1000px;
    margin-left: 100px;
  }
}

.goodsPrice {
  .el-collapse {
    width: 593px;
  }

  .el-collapse-item__header {
    color: #666;
  }
}

.remarkBox {
  .el-dialog__body {
    padding: 10px 20px;
  }

  .el-textarea__inner {
    height: 200px;
  }

  .el-dialog__footer {
    text-align: center;
  }
}

.payChannel {
 .el-radio-group{
  width: 500px;
  display: inline-block;
  .el-radio{
    width: 100px !important;
    display: inline-block
  }
  .el-radio__inner{
    position: relative;
    top: -1px;
  }
  img{
    width:18px;
    position: relative;
    top: 4px;
    margin-right: 10px;
  }
 }
}
.payDialog{
  .el-dialog__body{
    padding: 0 20px;
  }
}
</style>
